
import Vue from 'vue';

import { GetterTree, ActionTree, MutationTree } from 'vuex'

import {RootState} from "../index"
import BgServer from '~/modules/BgServer';
import DataSync from '~/modules/utility/DataSync';

import ModelListTop from '~/modules/ModelListTop';
import { sharedStorageLocal } from "~/modules/SharedStorage";

/***************************************************************/
/**                        STATE                              **/
/***************************************************************/

export const state = () => { return {
    topCredits:[] as ModelListTop[],
    topTournaments: [] as ModelListTop[],
    topRatings: [] as ModelListTop[],
    
    topCreditsHash: '' as string,    
    topTournamentsHash: '' as string,    
    topRatingsHash: '' as string,

    topCreditsHashLast: '' as string,    
    topTournamentsHashLast: '' as string,    
    topRatingsHashLast: '' as string  
};    
};

export type ModuleState = ReturnType<typeof state>


/***************************************************************/
/**                       GETTERS                             **/
/***************************************************************/

export const getters: GetterTree<ModuleState, RootState> = {     
}


/***************************************************************/
/**                      MUTATIONS                            **/
/***************************************************************/

export const mutations: MutationTree<ModuleState> = {
    SET_TOP_HASH (state, value) {
        for(let key in value)
            switch(key)
                {
                    case 'topCredits': state.topCredits = value['topCredits']; break;
                    case 'topTournaments': state.topTournaments = value['topTournaments'];break;
                    case 'topRatings': state.topRatings = value['topRatings'];break;

                    case 'topCreditsHash': state.topCreditsHash = value['topCreditsHash'];break;
                    case 'topTournamentsHash': state.topTournamentsHash = value['topTournamentsHash'];break;
                    case 'topRatingsHash': state.topRatingsHash = value['topRatingsHash'];break;

                    case 'topCreditsHashLast': state.topCreditsHashLast = value['topCreditsHashLast'];break;
                    case 'topTournamentsHashLast': state.topTournamentsHashLast = value['topTournamentsHashLast'];break;
                    case 'topRatingsHashLast': state.topRatingsHashLast = value['topRatingsHashLast'];break;
                }
    }
}



/***************************************************************/
/**                      ACTIONS                              **/
/***************************************************************/

let g_server:BgServer

export const actions: ActionTree<ModuleState, RootState> = {     
    async init({dispatch,commit}, server:BgServer) {                               
        g_server = server;

        g_server.socket.on(
            ModelListTop.EVENT_TOP_ACTIVE_PLAYERS_BY_RATING_CHANGED, 
            (value:any)=>{     
                commit('SET_TOP_HASH', {topRatings: value.players, topRatingsHash: value.leading_players_hash}); 
            }
        );
        g_server.socket.on(
            ModelListTop.EVENT_TOP_ACTIVE_PLAYERS_BY_CREDITS_CHANGED, 
            (value:any)=>{   
                commit('SET_TOP_HASH', {topCredits: value.players, topCreditsHash: value.leading_players_hash }); 
            }
        );
        g_server.socket.on(
            ModelListTop.EVENT_TOP_ACTIVE_PLAYERS_BY_TOURNAMENTS_CHANGED, 
            (value:any)=>{       
                commit('SET_TOP_HASH', {topTournaments: value.players, topTournamentsHash: value.leading_players_hash});
            }
        );

        commit(
            'SET_TOP_HASH', 
            {
                topCreditsHashLast: (sharedStorageLocal.get("topCreditsHashLast" ) || ""),
                topRatingsHashLast: (sharedStorageLocal.get("topRatingsHashLast" ) || ""),
                topTournamentsHashLast: (sharedStorageLocal.get("topTournamentsHashLast" ) || "")
            }
        );

        await this.dispatch("top/getTopCredits"); 
        await this.dispatch("top/getTopRatings");
        await this.dispatch("top/getTopTournaments");
    },

    async getTopTournaments({commit, state}, data ) {
        if(!g_server)
            return;    
        const data_s = await g_server.modelTop.getTopTournaments( );
        commit('SET_TOP_HASH', {topTournaments: data_s.players, topTournamentsHash: data_s.leading_players_hash}); 
        return data_s;
    },

    async getTopRatings({commit, state}, uuid ) {
        if(!g_server)
            return;    
        const data_s = await g_server.modelTop.getTopRatings( );
        commit('SET_TOP_HASH', {topRatings: data_s.players, topRatingsHash: data_s.leading_players_hash }); 
        return data_s;
    },

    async getTopCredits({commit, state}, uuid ) {
        if(!g_server)
            return;    
        const data_s = await g_server.modelTop.getTopCredits( ); 
        commit('SET_TOP_HASH', {topCredits: data_s.players, topCreditsHash: data_s.leading_players_hash }); 

        return data_s;
    },

    setTopHash ({commit, state}, formData) {
        commit('SET_TOP_HASH', formData);
    }
}






