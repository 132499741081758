
import Vue from 'vue';

import { GetterTree, ActionTree, MutationTree } from 'vuex'

import {RootState} from "../index"
import BgServer from '~/modules/BgServer';
import ModelCollectionGame from '~/modules/model/ModelCollectionGame';
import ModelListGame from '~/modules/ModelListGame';
import type ArraySortedGame from '~/modules/ArraySortedGame';
import Game, { GameUuid } from '~/modules/model/Types/Game';
import DataSync from '~/modules/utility/DataSync';





/***************************************************************/
/**                        STATE                              **/
/***************************************************************/


 /*
    lists consists:

    gamesJoinableAll: Array<Game>
    gamesJoinableMy: Array<Game>
    gamesJoinableOther: Array<Game>
        
    gamesActiveAll: Array<Game>
    gamesActiveMy: Array<Game>
    gamesActiveOther: Array<Game>

    gamesRevengeMy: Array<Game>
    gamesRevengeForMe: Array<Game>

    как пользоваться: 

    проверяешь gamesActiveMy - если есть открывешь игру 

    проверяешь gamesJoinableMy - если есть открывешь окно ожидания подключения к нами созданной игре    
                                 если нет то закрываешь это окно 
    проверяешь gamesRevengeMy - если есть открывешь окно ожидания подключения к нами созданному реваншу    
                                если нет то закрываешь это окно 
    проверяешь gamesRevengeForMe - если есть открывешь окно приглашения играть в реванш от другого игрока
                                если нет то закрываешь это окно 

    выводишь списко gamesJoinableOther - в окно списка игр 
    выводишь countJoinable  число - в Navbar в иконке игр (или можно просто gamesJoinableOther.length)
    выводишь countActiveOther  число - в Navbar в иконке идущих игр (или можно просто gamesActiveOther.length)
    выводишь gamesActiveAll - в окне идущие игры (gamesActiveAll а не gamesActiveOther - т.к. мы хотим видеть и свои игры тоже )
    */


export const state = () => { return {  

    //gamesAll: ModelCollectionGame,

    gamesJoinableAll: [] as Array<Game>,
    gamesJoinableMy: [] as Array<Game>,
    gamesJoinableOther: [] as Array<Game>,
        
    gamesActiveAll: [] as Array<Game>,
    gamesActiveMy: [] as Array<Game>,
    gamesActiveOther: [] as Array<Game>,

    gamesRevengeMy: [] as Array<Game>,
    gamesRevengeForMe: [] as Array<Game>,

    countJoinable: 0 as number,
    countActiveOther: 0 as number,
        
    gameHistory: [] as Game[],
    gameHistoryCount: null as number | null,
};    
};

export type ModuleState = ReturnType<typeof state>


/***************************************************************/
/**                       GETTERS                             **/
/***************************************************************/

export const getters: GetterTree<ModuleState, RootState> = {     
}


/***************************************************************/
/**                      MUTATIONS                            **/
/***************************************************************/

export const mutations: MutationTree<ModuleState> = {

    SYNC_ARRAYS( state , list:ArraySortedGame) {
        DataSync( state, list );
    }
        
}

/***************************************************************/
/**                      ACTIONS                              **/
/***************************************************************/

import {IGameCreateOptionsTrain, IGameCreateOptions} from "~/modules/connectors/GameConnector"
import ModelArray from '~/modules/model/ModelArray';

let g_server:BgServer

export const actions: ActionTree<ModuleState, RootState> = {  
        
    async init({state,dispatch,commit}, server:BgServer) { 
        g_server = server;
        
        server.modelGame.on( ModelListGame.EVENT_DATA_CHANGED , (list:ArraySortedGame)=>{    
            commit("SYNC_ARRAYS", list);

        })

        server.modelGame.on( ModelListGame.EVENT_HISTORY_GAMES, (arrayModels:ModelArray<Game> )=>{    
            commit("SYNC_ARRAYS", {                
                gameHistory : arrayModels.models,      
                gameHistoryCount : arrayModels.countTotal,
            });
        })
    },
    async doCreateGame({}, options:IGameCreateOptions) {
        return await g_server.modelGame.getConnector().doCreate(options);        
    },

    async doCreateTrain({}, options:IGameCreateOptionsTrain) {
        return await g_server.modelGame.getConnector().doTrain(options);
    },

    async doViewGame({}, gameUuId:GameUuid ) {
        return await g_server.modelGame.getConnector().doView(gameUuId);
    },
    
    async doRevengeCreate({}, gameUuId:GameUuid ) {
        return await g_server.modelGame.getConnector().doRevengeCreate(gameUuId);
    },

    async doRevengeConfirm({}, gameUuId:GameUuid ) {
        return await g_server.modelGame.getConnector().doRevengeConfirm(gameUuId);
    },

    async doRevengeReject({}, gameUuId:GameUuid ) {
        return await g_server.modelGame.getConnector().doRevengeReject(gameUuId);
    },

    async doJoin({}, gameUuId:GameUuid ) {
        return await g_server.modelGame.getConnector().doJoin(gameUuId);
    },

    async doCancel({}, gameUuId:GameUuid ) {
        return await g_server.modelGame.getConnector().doCancel(gameUuId);
    },


    async loadMoreHistoryGame({}) {
        return await g_server.modelGame.doLoadMoreHistoryGame();
    },    
}