
import Vue from 'vue';

import { GetterTree, ActionTree, MutationTree } from 'vuex'

import {RootState} from "../index"
import BgServer from '~/modules/BgServer';
import DataSync from '~/modules/utility/DataSync';

import TradeOffer from '~/modules/model/Types/TradeOffer';
import ModelCollectionTrade from '~/modules/model/ModelCollectionTrade';

import ModelListTrade from '~/modules/ModelListTrade';
import ArraySortedTrade from '~/modules/ArraySortedTrade';

/***************************************************************/
/**                        STATE                              **/
/***************************************************************/


/*
    содержит
    tradesGift:     Array<TradeOffer>    
    tradesBuyCoin:  Array<TradeOffer>    
    tradesBuyDesk:  Array<TradeOffer>    
    tradesBuyChips: Array<TradeOffer>
    tradesRepearKits: Array<TradeOffer>

    notifyCount:number

    как пользоваться: 

    notifyCount выводишь в navbar
    
    выводишь  tradesGift в окно подарка
        проверить доступно ли можно так
        let serverTimestamp = modelListTrade.getTimestamp();
        lists.tradesGift.forEach(trade => {
            let available = trade.isTimeAvailable(serverTimestamp);
            console.log("Gift "+trade.uuid+" avalaible = "+available); 
        });

    выводишь  tradesBuyCoin   в окно покупки монет
    выводишь  tradesBuyDesk   в окно покупки досок
    выводишь  tradesBuyChips  в окно покупки фишек    

    для раздела "мои покупки" см в ArraySortedAccount.ts
*/

export const state = () => { return {    
    //tradesAll:{} as ModelCollectionTrade,

    tradesGift:[] as TradeOffer[],
    tradesBuyCoin: [] as TradeOffer[],
    tradesBuyDesk: [] as TradeOffer[],
    tradesBuyChips: [] as TradeOffer[],
    tradesGiftAvailable: [] as TradeOffer[],  
    tradesRepearKits: [] as TradeOffer[],

    notifyCount: 0 as number,    
};    
};

export type ModuleState = ReturnType<typeof state>


/***************************************************************/
/**                       GETTERS                             **/
/***************************************************************/

export const getters: GetterTree<ModuleState, RootState> = {     
}


/***************************************************************/
/**                      MUTATIONS                            **/
/***************************************************************/

export const mutations: MutationTree<ModuleState> = {
    SYNC_ARRAYS( state , list:ArraySortedTrade) {
        DataSync( state, list );
    }        
}



/***************************************************************/
/**                      ACTIONS                              **/
/***************************************************************/

let g_server:BgServer

export const actions: ActionTree<ModuleState, RootState> = {     
    async init({dispatch,commit}, server:BgServer) {                                     
        g_server = server;
        
        server.modelTrade.on( ModelListTrade.EVENT_DATA_CHANGED , (list:ArraySortedTrade)=>{    
            commit("SYNC_ARRAYS", list);
        })
    },


    doExecute({}, uuid:string) {
        return g_server.connectorTrade.doExecute(uuid);        
    },

    doRefresh({} ) {
        return g_server.modelTrade.doDataFetch();
    },
}






